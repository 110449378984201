import { ThemeProvider } from '@leshen/ui'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import 'typeface-poppins'
import siteTheme from '../../theme'
import GlobalStyles from './CustomStyles'

const Wrapper = ({ children }) => {
  useEffect(() => {
    // Adding script dynamically to the document head
    const script1 = document.createElement('script')
    script1.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    script1.type = 'text/javascript'
    script1.charset = 'UTF-8'
    script1.setAttribute(
      'data-domain-script',
      '01917ad7-92a3-74cc-bf52-757cb9b33e9b'
    )
    document.head.appendChild(script1)

    // Adding inline script
    const script2 = document.createElement('script')
    script2.type = 'text/javascript'
    script2.text = 'function OptanonWrapper() { }'
    document.head.appendChild(script2)

    // Cleanup function to remove scripts when component unmounts
    return () => {
      document.head.removeChild(script1)
      document.head.removeChild(script2)
    }
  }, [])

  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyles theme={siteTheme} />
      {children}
    </ThemeProvider>
  )
}
Wrapper.defaultProps = {
  theme: {},
}

Wrapper.propTypes = {
  theme: PropTypes.object, // eslint-disable-line
  children: PropTypes.node.isRequired,
}

export default Wrapper
